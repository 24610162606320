import * as React from "react";
import { Farm, Layout, PageTitle } from "../components";
import Seo from "../components/Seo";

const FarmPage = () => {
  return (
    <Layout>
      <Seo title="FARM - emo Farmについて" />
      <PageTitle title="FARM" subTitle="emo Farmについて" />
      <Farm />
    </Layout>
  );
};

export default FarmPage;
